import * as React from "react";
import { cn } from "@/lib/utils";

interface ParagraphProps extends React.HTMLAttributes<HTMLParagraphElement> {}

export default function Paragraph({
  className,
  children,
  ...props
}: ParagraphProps) {
  return (
    <p className={cn("font-paragraph text-n400 ", className)} {...props}>
      {children}
    </p>
  );
}
