import React from "react";
import { cn } from "@/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";

const buttonVariants = cva(
  "inline-flex items-center justify-center gap-2 whitespace-nowrap text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
  {
    variants: {
      variant: {
        default: "bg-p300 text-white hover:bg-p300/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: "border border-p300 bg-background hover:bg-p300/15 text-p300",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-p300 underline-offset-4 hover:underline",
        frameHoverSolid: "", // Empty because we'll handle it in the component
        frameHoverOutline: "", // Empty because we'll handle it in the component
      },
      size: {
        default: "h-10 px-6 py-4",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  href?: string;
  fullWidth?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      href = null,
      fullWidth = false,
      type,
      ...props
    },
    ref
  ) => {
    if (variant?.includes("frameHover")) {
      const isOutlined = variant === "frameHoverOutline";
      if (href) {
        return (
          <a
            className={cn(
              "group relative inline-flex focus:outline-none",
              fullWidth ? "w-full sm:w-auto" : "w-auto",
              isOutlined ? "border border-p300" : "border border-p300",
              className
            )}
            href={href || ""}
            {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
          >
            <span
              className={cn(
                "w-full inline-flex items-center justify-center self-stretch px-4 py-2 text-sm text-center font-medium ring-1 transform transition-transform group-hover:-translate-y-1 group-hover:-translate-x-1 group-focus:-translate-y-1 group-focus:-translate-x-1",
                isOutlined
                  ? "bg-white text-p300 ring-p300 ring-offset-1"
                  : "bg-p300 text-white ring-p300 ring-offset-1 ring-offset-p300"
              )}
            >
              {props.children}
            </span>
          </a>
        );
      }
      return (
        <button
          className={cn(
            "group relative inline-flex focus:outline-none",
            fullWidth ? "w-full sm:w-auto" : "w-auto",
            isOutlined ? "border border-p300" : "border border-p300",
            className
          )}
          type={type}
          ref={ref}
          {...props}
        >
          <span
            className={cn(
              "w-full inline-flex items-center justify-center self-stretch px-4 py-2 text-sm text-center font-medium ring-1 transform transition-transform group-hover:-translate-y-1 group-hover:-translate-x-1 group-focus:-translate-y-1 group-focus:-translate-x-1",
              isOutlined
                ? "bg-white text-p300 ring-p300 ring-offset-1"
                : "bg-p300 text-white ring-p300 ring-offset-1 ring-offset-p300"
            )}
          >
            {props.children}
          </span>
        </button>
      );
    }

    if (href) {
      return (
        <a
          className={cn(
            buttonVariants({ variant, size, className }),
            fullWidth ? "w-full" : ""
          )}
          href={href}
          {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
        >
          {props.children}
        </a>
      );
    }

    return (
      <button
        className={cn(
          buttonVariants({ variant, size, className }),
          fullWidth ? "w-full" : ""
        )}
        type={type}
        ref={ref}
        {...props}
      />
    );
  }
);

Button.displayName = "Button";

export { Button, buttonVariants };
