import "./App.css";
import { lazy } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ArrowUp } from "lucide-react";
import { Toaster } from "./components/ui/toaster";
import ScrollToTop from "react-scroll-to-top";
import CookieNotifier from "./components/common/CookieNotifier";
import Footer from "./components/common/Footer";
import NavBar from "./components/common/navbar";

const Home = lazy(() => import("./pages/Home"));
const Contact = lazy(() => import("./pages/Contact"));
const About = lazy(() => import("./pages/About"));
const WebDev = lazy(() => import("./pages/services/WebDev"));
const UIUXDesign = lazy(() => import("./pages/services/UIUXDesign"));
const WebApps = lazy(() => import("./pages/services/WebApps"));
const WebShop = lazy(() => import("./pages/services/WebShop"));
const Maintenance = lazy(() => import("./pages/services/Maintenance"));
const Prices = lazy(() => import("./pages/Prices"));
const Impressum = lazy(() => import("./pages/Impressum"));
const Page404 = lazy(() => import("./pages/Page404"));
const Privacy = lazy(() => import("./pages/Privacy"));
const Cookies = lazy(() => import("./pages/Cookies"));

function App() {
  return (
    <div className="overflow-hidden">
      <HelmetProvider>
        <NavBar />
        <Toaster />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/services/web-development" element={<WebDev />} />
            <Route path="/services/web-applications" element={<WebApps />} />
            <Route path="/services/ui-ux-design" element={<UIUXDesign />} />
            <Route path="/services/webshop-development" element={<WebShop />} />
            <Route path="/services/maintenance" element={<Maintenance />} />
            <Route path="/prices" element={<Prices />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/cookies" element={<Cookies />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </BrowserRouter>
        <ScrollToTop
          smooth
          component={<ArrowUp className="w-10 h-10 text-p300" />}
          className="bg-n0"
          style={{ zIndex: 100 }}
        />
        <CookieNotifier />
        <Footer />
      </HelmetProvider>
    </div>
  );
}

export default App;
