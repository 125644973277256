import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function CookieNotifier() {
  const [open, setOpen] = useState(!localStorage.getItem("cookies"));
  const { t } = useTranslation();

  const handleAction = (value: boolean) => {
    localStorage.setItem("cookies", value.toString());
    setOpen(false);
  };

  if (!open) return null;

  return (
    <div className="fixed bottom-4 z-50 left-1/2 transform -translate-x-1/2 bg-n0 shadow-lg rounded-xl p-4 flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4 w-11/12 max-w-md">
      <i className="text-2xl">🍪</i>
      <span className="text-sm text-n700">
        {t("cookie_banner.desc")}{" "}
        <a href="/cookies" className="text-p300 hover:underline">
          {t("cookie_policy.title")}
        </a>
      </span>
      <div className="md:pl-4 flex space-x-4">
        <button
          className="bg-p300 text-white rounded-full text-sm px-4 py-2 hover:bg-p500 transition-colors text-nowrap"
          onClick={() => handleAction(true)}
        >
          {t("cookie_banner.understand")}
        </button>
        {/* <button
          className="bg-n50 text-n700 rounded-full text-sm px-4 py-2 hover:bg-n70 transition-colors"
          onClick={() => handleAction(false)}
        >
          {t("not_understand")}
        </button> */}
      </div>
    </div>
  );
}
