import { useState } from "react";
import { Menu, X } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Button } from "../ui/button";
import Paragraph from "./Paragraph";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <div className="container mx-auto px-4">
      <nav className="bg-transparent">
        <div className="mx-auto">
          <div className="grid grid-cols-3 items-center">
            <div className="flex space-x-7 z-50">
              <a href="/" className="flex items-center py-4 px-2">
                <img
                  src="/icon.svg"
                  alt={"Webby" + " " + t("logo")}
                  title="Webby"
                  className="w-36 bg-transparent"
                />
              </a>
            </div>
            <div className="hidden lg:flex justify-center">
              <div className="flex items-center space-x-3">
                <DropdownMenu>
                  <DropdownMenuTrigger className="focus:outline-none">
                    <div className="py-4 px-2">
                      <Paragraph className="hover:text-p300 text-lg">
                        {t("services.title")}
                      </Paragraph>
                    </div>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="z-50">
                    <a href="/services/web-development">
                      <DropdownMenuItem className="py-3 group hover:cursor-pointer">
                        <Paragraph className="group-hover:text-p300 text-lg">
                          {t("webdev.name")}
                        </Paragraph>
                      </DropdownMenuItem>
                    </a>
                    <a href="/services/web-applications">
                      <DropdownMenuItem className="py-3 group hover:cursor-pointer">
                        <Paragraph className="group-hover:text-p300 text-lg">
                          {t("webapps.name")}
                        </Paragraph>
                      </DropdownMenuItem>
                    </a>
                    <a href="/services/ui-ux-design">
                      <DropdownMenuItem className="py-3 group hover:cursor-pointer">
                        <Paragraph className="group-hover:text-p300 text-lg">
                          {t("uiux.name")}
                        </Paragraph>
                      </DropdownMenuItem>
                    </a>
                    <a href="/services/webshop-development">
                      <DropdownMenuItem className="py-3 group hover:cursor-pointer">
                        <Paragraph className="group-hover:text-p300 text-lg">
                          {t("webshop.name")}
                        </Paragraph>
                      </DropdownMenuItem>
                    </a>
                    <a href="/services/maintenance">
                      <DropdownMenuItem className="py-3 group hover:cursor-pointer">
                        <Paragraph className="group-hover:text-p300 text-lg">
                          {t("maintenance.name")}
                        </Paragraph>
                      </DropdownMenuItem>
                    </a>
                  </DropdownMenuContent>
                </DropdownMenu>
                <a href="/prices" className="py-4 px-2">
                  <Paragraph className="hover:text-p300 text-lg">
                    {t("prices.name")}
                  </Paragraph>
                </a>
                <a href="/about" className="py-4 px-2">
                  <Paragraph className="hover:text-p300 text-lg">
                    {t("about.name")}
                  </Paragraph>
                </a>
              </div>
            </div>
            <div className="flex items-center justify-end col-span-2 lg:col-span-1">
              <div className="hidden lg:flex items-center space-x-3">
                <LanguageSelector />

                <Button variant={"frameHoverSolid"} href="/contact">
                  {t("contact.name")}
                </Button>
              </div>
              <div className="lg:hidden">
                <button
                  className="outline-none mobile-menu-button"
                  onClick={toggleMenu}
                  aria-label="Toggle mobile menu"
                  aria-expanded={isOpen}
                  aria-controls="mobile-menu"
                >
                  {isOpen ? (
                    <X className="h-6 w-6 text-gray-500" />
                  ) : (
                    <Menu className="h-6 w-6 text-gray-500" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        {isOpen && (
          <div id="mobile-menu" className="lg:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <Accordion
                type="multiple"
                className="border-none data-[state=open]:bg-n0"
              >
                <AccordionItem
                  value="item-1"
                  className="border-none data-[state=open]:bg-n0"
                >
                  <AccordionTrigger className="border-none data-[state=open]:bg-n0 hover:no-underline px-3 py-2 hover:bg-n20 data-[state=open]:hover:bg-n20">
                    {t("services.title")}
                  </AccordionTrigger>
                  <a href="/services/web-development">
                    <AccordionContent className="data-[state=open]:bg-n0 data-[state=open]:hover:bg-n20 pl-2 py-2">
                      {t("webdev.name")}
                    </AccordionContent>
                  </a>
                  <a href="/services/web-applications">
                    <AccordionContent className="data-[state=open]:bg-n0 data-[state=open]:hover:bg-n20 pl-2 py-2">
                      {t("webapps.name")}
                    </AccordionContent>
                  </a>
                  <a href="/services/ui-ux-design">
                    <AccordionContent className="data-[state=open]:bg-n0 data-[state=open]:hover:bg-n20 pl-2 py-2">
                      {t("uiux.name")}
                    </AccordionContent>
                  </a>
                  <a href="/services/webshop-development">
                    <AccordionContent className="data-[state=open]:bg-n0 data-[state=open]:hover:bg-n20 pl-2 py-2">
                      {t("webshop.name")}
                    </AccordionContent>
                  </a>
                  <a href="/services/maintenance">
                    <AccordionContent className="data-[state=open]:bg-n0 data-[state=open]:hover:bg-n20 pl-2 py-2">
                      {t("maintenance.name")}
                    </AccordionContent>
                  </a>
                </AccordionItem>
              </Accordion>
              <a
                href="/prices"
                className="block px-3 py-2 text-base font-medium hover:bg-n20"
              >
                {t("prices.name")}
              </a>
              <a
                href="/about"
                className="block px-3 py-2 text-base font-medium hover:bg-n20"
              >
                {t("about.name")}
              </a>
            </div>
            <div className="px-5 py-3 border-t border-gray-200 flex justify-between">
              <div className="flex items-center">
                <LanguageSelector />
              </div>
              <Button variant={"frameHoverSolid"} href="/contact">
                {t("contact.name")}
              </Button>
            </div>
          </div>
        )}
      </nav>
    </div>
  );
};

export default NavBar;
