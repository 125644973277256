import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import Paragraph from "./Paragraph";

type LanguageType = "EN" | "HU";

export default function LanguageSelector() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const [language, setLanguage] = useState<LanguageType>(() => {
    return (localStorage.getItem("preferred-language") as LanguageType) || "HU";
  });

  useEffect(() => {
    const storedLang =
      localStorage.getItem("preferred-language")?.toLowerCase() || "hu";
    i18n.changeLanguage(storedLang);
  }, [i18n]);

  const handleLanguageChange = (newLanguage: LanguageType) => {
    const lowerLang = newLanguage.toLowerCase();
    setLanguage(newLanguage);
    localStorage.setItem("preferred-language", newLanguage);
    i18n.changeLanguage(lowerLang);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <a 
          className="w-full hover:cursor-pointer"
          role="button"
          aria-haspopup="menu"
          aria-expanded={open}
          onClick={() => open ? setOpen(false) : setOpen(true)}
        >
          <img
            src={
              language === "HU"
                ? "/images/icons/HUN.svg"
                : "/images/icons/UK.svg"
            }
            alt="language flag"
          />
        </a>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mt-4 z-50">
        <DropdownMenuItem onClick={() => handleLanguageChange("EN")} className="hover:cursor-pointer">
          <div className="flex">
            <img
              src="/images/icons/UK.svg"
              alt={t("flags.uk")}
              className="mr-2"
            />
            <Paragraph>EN</Paragraph>
          </div>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleLanguageChange("HU")} className="hover:cursor-pointer">
          <div className="flex justify-between">
            <img
              src="/images/icons/HUN.svg"
              alt={t("flags.hu")}
              className="mr-2"
            />
            <Paragraph>HU</Paragraph>
          </div>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
